import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import TagsSelect from 'components/TagsSelect/TagsSelect';
import Spinner from 'components/Spinner/Spinner';

import { UpsellingItemsStore } from 'stores/Forms/DishForm/UpsellingItemsStore';

import styles from './UpsellingItems.module.scss';

interface UpsellingItemsProps {
  store: UpsellingItemsStore;
}

@observer
export class UpsellingItems extends Component<UpsellingItemsProps> {
  switchUpsellingList = (
    value: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.props.store.onToggleList(event.target.name, value);
  };

  render() {
    const { store } = this.props;

    return (
      <div className={styles.groups}>
        {store.loading && <Spinner contain />}
        {Object.entries(store.lists).map(([listId, list]) => (
          <div className={styles.group} key={listId}>
            <label className={styles.label}>
              <div>
                {list.title}
                {' - '}
                <FormattedMessage id="upselling.group.toggle.label" />
              </div>
              <SwitchButton
                name={listId}
                onChange={this.switchUpsellingList}
                checked={list.enabled}
              />
            </label>
            {list.enabled && (
              <TagsSelect
                title="upselling.items.title"
                tags={store.menuItems}
                selectedTags={list.items}
                onSubmit={items => store.selectItems(listId, items)}
                onRemoveTag={id => store.removeItems(listId, id)}
                isShowSearchField={true}
                isShowTags={false}
                withGroups={true}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}
