import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import style from './Allergen.module.scss';

import Allergen from './Allergen';

import DishForm from 'stores/Forms/DishForm/DishForm';

@inject('form')
@observer
class Allergens extends Component<{ form?: DishForm }> {
  render() {
    const { form } = this.props;
    const { sortedAllergens } = form!;

    return (
      <ul className={style.allergens__list}>
        {sortedAllergens?.map(it => (
          <li key={it.id} className={style.allergen__item}>
            <Allergen
              allergen={it}
              isActive={form!.itemAllergens.includes(it.id)}
            />
          </li>
        ))}
      </ul>
    );
  }
}

export default Allergens;
