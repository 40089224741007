import React from 'react';
import './styles.scss';
import Icon from 'components/Icon/Icon';
import OnlineOrdersStore from 'stores/OnlineOrders/OnlineOrdersStore';
import Button from 'components/Button/Button';
import ModalStore from 'stores/ModalStore';
import OnlineOrderModal from 'components/Modals/OnlineOrderModal/OnlineOrderModal';
import { observer } from 'mobx-react';
import { SwipeDirection } from 'stores/UserProfile/types';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import { DATE_FORMAT_ORDERS } from '../../../helpers/datetime';
import RootStore from '../../../stores/RootStore';
import SelectField from '../../../components/Form/Fields/Select/SelectField';
import { injectIntl, WrappedComponentProps } from 'react-intl';

@observer
class OnlineOrdersHeader extends React.Component<
  {
    store: OnlineOrdersStore;
    onUpdateOrderStatus: (id: string) => void;
    onFilterClick: () => void;
  } & WrappedComponentProps
> {
  handleScanQR = async () => {
    await ModalStore.showModal(OnlineOrderModal, {
      onUpdateOrderStatus: this.props.onUpdateOrderStatus,
    });
  };

  onSearch = value => {
    this.props.store.searchOrder(value);
  };

  onArrowClick = (value: SwipeDirection) => () => {
    this.props.store!.swipeDay(value);
  };

  render() {
    const {
      searchValue,
      activeDate,
      isMobileOrTablet,
      changeDate,
      isCurrentDay,
    } = this.props.store;

    return (
      <div className={'onlineOrdersHeader__search__row'}>
        <div className={'onlineOrdersHeader__date'}>
          {!isMobileOrTablet && (
            <>
              <Button
                styleType="text"
                onClick={this.onArrowClick('previous')}
                className={'onlineOrdersHeader__arrowButton'}
              >
                <Icon type="arrowLeft" />
              </Button>

              <Button
                styleType="text"
                onClick={this.onArrowClick('next')}
                className={'onlineOrdersHeader__arrowButton'}
              >
                <Icon type="arrowRight" />
              </Button>
            </>
          )}

          <Datepicker
            value={activeDate}
            onChange={changeDate}
            selectRange={false}
            showPeriods={false}
            styles={{
              button: 'onlineOrdersHeader__datePicker__button',
            }}
            icon={true}
            dateFormat={DATE_FORMAT_ORDERS}
            locale={RootStore.localization.locale}
          />
        </div>

        <Button
          styleType="text"
          className={'onlineOrdersHeader__filter__button'}
          onClick={this.props.onFilterClick}
        >
          <Icon type="filter" />
          <span className="button__text">Filter</span>
        </Button>

        <div className={'onlineOrdersHeader__search__container'}>
          <input
            type="text"
            placeholder={`${this.props.intl.formatMessage({
              id: 'title.order.number',
            })} / ${this.props.intl.formatMessage({
              id: 'title.order.name',
            })}`}
            value={searchValue}
            onChange={e => this.onSearch(e.target.value)}
            className={'onlineOrdersHeader__searchInput'}
          />

          {isMobileOrTablet && isCurrentDay && (
            <Button
              styleType="text"
              className={'onlineOrdersHeader__btn__qr'}
              onClick={this.handleScanQR}
            >
              <Icon type="qr" />
            </Button>
          )}
        </div>
      </div>
    );
  }
}
export default injectIntl(OnlineOrdersHeader);
