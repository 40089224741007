import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import style from './BaseSpecifications.module.scss';

import DishForm from 'stores/Forms/DishForm/DishForm';

import { DishAspect } from 'stores/Menu/types';

interface Props {
  aspect: DishAspect;
  isActive: boolean;
  form?: DishForm;
}

@inject('form')
@observer
class FormSpec extends Component<Props> {
  onSpecChange = id => () => {
    this.props.form!.toggleSpec(id);
  };

  render() {
    const { aspect, isActive } = this.props;
    return (
      <div className={style.item}>
        <input
          type="checkbox"
          name="tag"
          id={aspect.id}
          className={style.checkbox}
          onChange={this.onSpecChange(aspect.id)}
          checked={isActive}
        />
        <label className={style.label} htmlFor={aspect.id}>
          {aspect.title}
        </label>
      </div>
    );
  }
}

export default FormSpec;
