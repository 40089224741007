import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import './style.scss';

import config from './columnsConfig';
import { createStickerContent } from './helpers';
import Table from 'components/Table/Table';

import Orders from './Orders/Orders';
import OnlineOrdersHeader from './OnlineOrdersHeader/OnlineOrdersHeader';
import OnlineOrderModal from 'components/Modals/OnlineOrderModal/OnlineOrderModal';
import FilterModal from 'components/Modals/OnlineOrderFilterModal/OnlineOrderFilterModal';

import OnlineOrdersStore from 'stores/OnlineOrders/OnlineOrdersStore';
import ModalStore from 'stores/ModalStore';
import { OrderModel } from 'stores/UserProfile/types';
import RootStore from 'stores/RootStore';
import SoundPlayer from './SoundPlayer';

@observer
class OnlineOrdersPage extends React.Component<{ intl }> {
  store = new OnlineOrdersStore();

  async componentDidMount() {
    await this.store.init();
    RootStore.socket.subscribe(this.store.orderCreated);
  }

  componentWillUnmount() {
    this.store.clearInterval();
    RootStore.socket.unsubscribe(this.store.orderCreated);
  }

  handleOpenOrder = async orderNumber => {
    const currentOrder = this.store?.orders?.find?.(
      order => order.queueNumber === orderNumber,
    );
    await ModalStore.showModal(OnlineOrderModal, {
      order: currentOrder,
      onUpdateOrderStatus: this.onUpdateOrderStatus,
      activeDate: this.store.activeDate,
      onPrintSticker: this.handlePrintSticker,
    });
  };

  handlePrintSticker = (orderItem: OrderModel) => {
    createStickerContent(
      orderItem,

      {
        queueNumberTitle: this.props.intl.formatMessage(
          { id: 'user.order.number.full' },
          { number: orderItem.queueNumber },
        ),
        deliveryLocationTitle: this.props.intl.formatMessage(
          { id: 'user.order.delivery.location' },
          { location: orderItem?.deliveryLocation?.title ?? '' },
        ),
      },
    );
    window.print();
  };

  onUpdateOrderStatus = (id: string) => {
    this.store.orderStateUpdated(id);
  };

  handleFilterClick = async () => {
    const { intl } = this.props;
    await ModalStore.showModal(FilterModal, {
      onApplyFilter: this.store.applyFilter,
      filters: this.store.filters,
      orderLocations: this.store.orderLocations,
      intl,
    });
  };

  render() {
    const {
      isMobileOrTablet,
      orders,
      loading,
      depositItemsConfirmation,
    } = this.store;

    return (
      <>
        <div className={'onlineOrders__main'}>
          <OnlineOrdersHeader
            store={this.store}
            onUpdateOrderStatus={this.onUpdateOrderStatus}
            onFilterClick={this.handleFilterClick}
          />

          {depositItemsConfirmation.size > 0 && (
            <div className={'depositItemsConfirmation'}>
              {[...depositItemsConfirmation.entries()].map(entry => {
                const deviceName = entry[0];
                const pin = entry[1];
                return (
                  <div key={deviceName}>
                    <FormattedMessage
                      id="deposit.items.confirmation.request.message"
                      values={{ deviceName, pin }}
                    />{' '}
                    <span
                      className="close"
                      onClick={() => {
                        depositItemsConfirmation.delete(deviceName);
                      }}
                    >
                      x
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          <SoundPlayer
            autoplay={depositItemsConfirmation.size !== 0}
            src="/deposit-items-sound.mid"
            loop={false}
          />

          {isMobileOrTablet ? (
            <Orders
              orders={orders}
              onOpenOrder={this.handleOpenOrder}
              onPrintSticker={this.handlePrintSticker}
            />
          ) : (
            <Table
              data={orders}
              columns={config({
                onOpenOrder: this.handleOpenOrder,
                onPrintSticker: this.handlePrintSticker,
              })}
              loading={loading}
            />
          )}
        </div>
        {/* Print sticker*/}
        <div id={'print'} />
      </>
    );
  }
}
export default injectIntl(OnlineOrdersPage);
