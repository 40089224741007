import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import FormAspect from './FormSpec';
import DishForm from 'stores/Forms/DishForm/DishForm';
import style from './BaseSpecifications.module.scss';
import { Specification, SpecificationType } from 'stores/Menu/types';
import { FormattedMessage } from 'react-intl';

@inject('form')
@observer
class BaseSpecifications extends Component<{
  form?: DishForm;
  baseSpecifications?: Specification[];
}> {
  render() {
    const { form } = this.props;
    const { baseSpecifications } = form!;
    if (!baseSpecifications || !baseSpecifications.length) {
      return null;
    }

    return baseSpecifications?.map?.(baseSpecification => {
      if (baseSpecification.name !== SpecificationType.ADDITIVES) {
        return (
          <div className={style.form__group} key={baseSpecification.id}>
            <h3 className={style.group__title}>
              <FormattedMessage id={baseSpecification.name} />
            </h3>
            <ul className={style.list}>
              {baseSpecification?.values?.map(item => (
                <li key={item.id}>
                  <FormAspect
                    aspect={item}
                    isActive={form!.itemSpecs.includes(item.id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }
}

export default BaseSpecifications;
