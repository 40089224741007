import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import style from './Allergen.module.scss';

import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';

import DishForm from 'stores/Forms/DishForm/DishForm';

import { AllergenType } from 'stores/Menu/types';

interface Props {
  allergen: AllergenType;
  isActive: boolean;
  form?: DishForm;
}

@inject('form')
@observer
class Allergen extends Component<Props> {
  onAllergenClick = type => () => {
    this.props.form!.toggleAllergen(type);
  };

  render() {
    const { allergen, isActive } = this.props;

    return (
      <CheckboxField
        className={{ wrapper: style.allergen }}
        checked={isActive}
        onChange={this.onAllergenClick(allergen.id)}
        type="green"
      >
        <img src={allergen.icon} alt="icon" className={style.icon} />
        <span className={style.title}>{allergen.title}</span>
      </CheckboxField>
    );
  }
}

export default Allergen;
