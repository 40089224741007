import http from 'http/http';
import URLS from 'http/urls';
import { api } from 'http/helpers';
import {
  DeliveryTimeSlot,
  OnlineOrderPreviewDto,
  OnlineOrderPreviewRequest,
  OnlineOrderRequest,
  Order,
  OrderModel,
} from 'stores/UserProfile/types';
import { OnlineOrderGroup, OrderLocation } from 'stores/OnlineOrders/type';
import { FeedbackDTO } from 'http/types';

interface OnlineOrdersParams {
  from: string;
  to: string;
  after?: string;
}

export const onlineOrdersApi = {
  fetchOnlineOrders(params: OnlineOrdersParams) {
    return http.get<OnlineOrderGroup[]>(api(URLS.onlineOrder.onlineOrders), {
      params,
    });
  },

  fetchSearchTodayOrderByQR(qr: string) {
    return http.get<OrderModel>(api(URLS.onlineOrder.searchTodayByQr), {
      replacePathParams: { qrPayload: qr },
    });
  },

  fetchSearchOrderByQueueNumber(queueNumber: string) {
    return http.get<OrderModel>(api(URLS.onlineOrder.searchByQueueNumber), {
      replacePathParams: { queueNumber },
    });
  },

  fetchMarkOrderDelivered(orderId: string) {
    return http.post(
      api(URLS.onlineOrder.orderDelivered),
      {},
      {
        replacePathParams: { id: orderId },
      },
    );
  },

  fetchOrderProcessingFinished(orderId: string) {
    return http.post(
      api(URLS.onlineOrder.orderProcessingFinished),
      {},
      {
        replacePathParams: { id: orderId },
      },
    );
  },

  fetchOrderLocations() {
    return http.get<OrderLocation[]>(api(URLS.onlineOrder.orderLocations), {});
  },

  fetchTimeSlots(date: string) {
    return http.get<DeliveryTimeSlot[]>(api(URLS.onlineOrder.timeSlotsAll), {
      replacePathParams: { date },
    });
  },

  fetchEmployeeOnlineOrders(employeeId: string) {
    return http.get<OrderModel[]>(api(URLS.onlineOrder.employeeOrders), {
      replacePathParams: { id: employeeId },
    });
  },

  fetchFeedback(data: FeedbackDTO) {
    return http.post(api(URLS.onlineOrder.feedback), data);
  },

  saveOnlineOrder(data: OnlineOrderRequest) {
    return http.post<OnlineOrderRequest, Order>(
      api(URLS.onlineOrder.orders),
      data,
    );
  },

  previewOnlineOrder(data: OnlineOrderPreviewRequest) {
    return http.post<OnlineOrderPreviewRequest, OnlineOrderPreviewDto>(
      api(URLS.onlineOrder.orderPreview),
      data,
    );
  },

  fetchDepositItemsRequests() {
    return http.get<{ [key: string]: string }>(
      api(URLS.onlineOrder.depositItemsConfirmationRequests),
    );
  },
};
