import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import Button from 'components/Button/Button';
import SelectField, { Option } from 'components/Form/Fields/Select/SelectField';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';

import './OnlineOrderFilterModal.scss';

@observer
class FilterModal extends Component<
  {
    onClose: () => void;
    onApplyFilter: (filters: { place: string[]; status: string }) => void;
    filters: { place: string[]; status: string };
    orderLocations: Array<Option<string>>;
  } & WrappedComponentProps
> {
  state = {
    selectedPlaces: this.props.filters.place || [],
    selectedStatus: this.props.filters.status || ' ',
  };

  handlePlaceChange = (
    values: string | string[] | null | undefined,
    // options: Option<string> | Option<string>[] | null | undefined
  ) => {
    this.setState({ selectedPlaces: Array.isArray(values) ? values : [] });
  };

  handleStatusChange = (
    value: string | string[] | null | undefined,
    // option: Option<string> | Option<string>[] | null | undefined
  ) => {
    this.setState({ selectedStatus: typeof value === 'string' ? value : ' ' });
  };

  applyFilter = () => {
    const { selectedPlaces, selectedStatus } = this.state;
    this.props.onApplyFilter({ place: selectedPlaces, status: selectedStatus });
    this.props.onClose();
  };

  render() {
    const { intl } = this.props;
    const placeOptions: Array<Option<string>> = [
      {
        value: 'ALL',
        label: intl.formatMessage({ id: 'title.order.places.all' }),
      },
      { value: 'TAKE_AWAY', label: intl.formatMessage({ id: 'PICKUP' }) },
      {
        value: 'IN_PLACE',
        label: intl.formatMessage({
          id: 'modal.online.orders.filter.label.inplace',
        }),
      },
    ];

    const statusOptions: Array<Option<string>> = [
      {
        value: ' ',
        label: intl.formatMessage({
          id: 'modal.online.orders.filter.status.all',
        }),
      },
      {
        value: 'delivered',
        label: intl.formatMessage({ id: 'title.order.control.status' }),
      },
      {
        value: 'pending',
        label: intl.formatMessage({
          id: 'modal.online.orders.filter.status.pending',
        }),
      },
    ];

    const isApplyButtonDisabled =
      this.state.selectedPlaces.length === 0 && !this.state.selectedStatus;

    return (
      <Modal
        className={'filterModal'}
        onClose={this.props.onClose}
        destroyOnClose
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <div className={'filterModal__title'}>
          <FormattedMessage id={'modal.online.orders.filter.title'} />
        </div>
        <div className={'filterModal__field'}>
          <label>
            <FormattedMessage id="modal.online.orders.filter.label.location" />
          </label>
          <SelectField<string>
            value={this.state.selectedPlaces}
            onChange={this.handlePlaceChange}
            options={placeOptions}
            isMulti={true}
          />
        </div>

        <div className={'filterModal__field'}>
          <label>
            <FormattedMessage id="modal.online.orders.filter.label.status" />
          </label>
          <SelectField<string>
            value={this.state.selectedStatus}
            onChange={this.handleStatusChange}
            options={statusOptions}
          />
        </div>

        <div className={'filterModal__actions'}>
          <Button
            className={'filterModal__button'}
            styleType="ghost"
            onClick={this.props.onClose}
          >
            <FormattedMessage id="button.cancel" />
          </Button>

          <Button
            className={'filterModal__button'}
            type="submit"
            onClick={this.applyFilter}
            disabled={isApplyButtonDisabled}
          >
            <FormattedMessage id="button.filter" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FilterModal;
