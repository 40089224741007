import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { DishGroupManagement, MenuItem } from './types';
import ModalStore from '../ModalStore';

import WarningModal from 'components/Modals/Warning/WarningModal';

import Log from 'helpers/log';
import { sortByAccessor } from 'helpers/accessors';
import { SortConfig } from 'helpers/types';
import { DEFAULT_PAGE } from '../constants';
import { validVatRate } from '../Subsidies/types';

const DEFAULT_CATEGORIES_PAGE_SIZE = 20;

class CategoriesStore {
  @observable items: MenuItem[] = [];

  @observable activeCategoryID;
  @observable searchValue = '';
  @observable size = DEFAULT_CATEGORIES_PAGE_SIZE;
  @observable page = DEFAULT_PAGE;
  @observable totalMenuItems: number;
  @observable pageCount: number;

  @observable loading = false;

  @observable private _categories: DishGroupManagement[] = [];

  private defaultItemSort: SortConfig = { accessor: 'title', desc: false };
  private defaultCategorySort: SortConfig = { accessor: 'title', desc: false };

  @computed
  get categories() {
    return this._categories
      .slice()
      .sort(sortByAccessor(this.defaultCategorySort));
  }

  @computed
  get activeCategory() {
    return this.activeCategoryID
      ? this.categories.filter(
          category => category.id === this.activeCategoryID,
        )[0]
      : null;
  }

  @computed
  get filteredItems() {
    return this.items.filter(
      el =>
        this.activeCategoryID === el.dishGroup?.id || !this.activeCategoryID,
    );
  }

  async init() {
    this.loading = true;

    await Promise.all([
      this.fetchCategories(),
      this.fetchMenuItemsPageable(this.page),
    ]);

    this.loading = false;
  }

  @action.bound
  async changePage(page: number) {
    this.searchValue
      ? await this.searchMenuItems(this.searchValue, page)
      : await this.fetchMenuItemsPageable(page);
  }

  @action.bound
  async searchMenuItems(searchValue: string, page?: number) {
    this.searchValue = searchValue;
    this.page = 0;

    if (!searchValue) {
      return this.fetchMenuItemsPageable(this.page);
    }
    this.loading = true;
    try {
      const { data } = await httpFacade.menuItems.fetchMenuItemsSearch(
        this.size,
        page || this.page,
        searchValue,
        this.activeCategoryID,
      );
      const {
        content,
        totalPages,
        number: currentPage,
        size,
        totalElements,
      } = data;
      this.items = content.slice().sort(sortByAccessor(this.defaultItemSort));
      this.pageCount = totalPages;
      this.page = currentPage;
      this.size = size;
      this.totalMenuItems = totalElements;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteCategory(id) {
    const isMenuItemsExist = this.items.some(item => item.dishGroup.id === id);
    await ModalStore.showModal(WarningModal, {
      title: isMenuItemsExist ? ' ' : 'modal.warning.title.delete',
      description: isMenuItemsExist
        ? 'modal.warning.delete.category'
        : 'modal.warning.delete.item',
      isShowConrols: !isMenuItemsExist,
    });
    if (isMenuItemsExist) {
      return;
    }

    try {
      await httpFacade.menu.deleteCategory(id);

      this._categories = this.categories.filter(category => category.id !== id);
      this.items = this.items.filter(item => item.dishGroup.id !== id);
      this.activeCategoryID =
        this.activeCategoryID === id ? '' : this.activeCategoryID;
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async deleteMenuItem(id) {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });

    try {
      this.loading = false;
      await httpFacade.menuItems.deleteMenuItem(id);

      this.items = this.items.filter(item => item.id !== id);
      const selectedPage =
        !this.items.length && !!this.page ? this.page - 1 : this.page;
      this.searchValue
        ? await this.searchMenuItems(this.searchValue, selectedPage)
        : await this.fetchMenuItemsPageable(selectedPage);
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateMenuItemDescription(id, description) {
    try {
      const { data: menuItemData } = await httpFacade.menuItems.fetchMenuItem(
        id,
      );
      const { data } = await httpFacade.menuItems.updateMenuItem(id, {
        ...menuItemData,
        specification: [
          ...menuItemData.specification,
          ...(typeof menuItemData.healthAspects !== 'undefined'
            ? menuItemData.healthAspects
            : []),
        ],
        allergens: menuItemData.allergens.map(it => it.id),
        labels: menuItemData.labels.map(it => it.id),
        description,
        upsellingLists: menuItemData.upsellingLists ?? [],
      });
      data.dishGroup = { id: data.dishGroup } as any;

      this.updateMenuItems(data);
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async fetchCategories() {
    try {
      const { data } = await httpFacade.menu.fetchCategories();

      this._categories = data;
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async fetchMenuItemsPageable(selectedPage: number) {
    this.loading = true;
    try {
      const { data } = await httpFacade.menuItems.fetchMenuItemsPageable(
        this.activeCategoryID,
        this.size,
        selectedPage,
      );
      const {
        content,
        totalPages,
        number: currentPage,
        size,
        totalElements,
      } = data;
      this.items = content.slice().sort(sortByAccessor(this.defaultItemSort));

      this.pageCount = totalPages;
      this.page = currentPage;
      this.size = size;
      this.totalMenuItems = totalElements;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateCategories(payload: DishGroupManagement) {
    const category = this.categories.find(item => item.id === payload.id);

    this._categories = category
      ? this.categories.map(item => (item.id === payload.id ? payload : item))
      : [...this.categories, payload];
  }

  @action.bound
  updateMenuItems(payload: MenuItem) {
    const menuItem = this.items.find(items => items.id === payload.id);
    this.items = menuItem
      ? this.items.map(item => {
          return item.id === payload.id ? { ...item, ...payload } : item;
        })
      : [...this.items, payload];
  }

  @action.bound
  createMenuItems(payload: MenuItem) {
    this.items.push(payload);
  }

  @action.bound
  async changeCategory(id: string) {
    this.activeCategoryID = id;
    this.searchValue = '';
    await this.fetchMenuItemsPageable(0);
  }

  @computed
  get categorySubsidisedStatus() {
    return 1;
  }

  @action.bound
  async changeSubsidisedMenuItem(id: string, isSubsidised: boolean) {
    await httpFacade.menuItems.markSubsidisedMenuItem(id, isSubsidised);
    this.items = this.items.map(item => {
      if (item.id === id && item.vatRates.includes(validVatRate)) {
        item.subsidized = isSubsidised;
      }
      return item;
    });
  }

  @action.bound
  async changeSubsidisedCategory(isSubsidised: boolean) {
    await httpFacade.menuItems.markSubsidisedCategory(
      this.activeCategoryID,
      isSubsidised,
    );
    this.items = this.items.map(item => {
      if (item.vatRates.includes(validVatRate) || !isSubsidised) {
        item.subsidized = isSubsidised;
      }
      return item;
    });
  }

  @action.bound
  async changeMenuItemKioskSelfCheckoutVisibility(menuItem: MenuItem) {
    await httpFacade.menuItems.changeNotVisibleKioskSelfCheckout(
      menuItem.id,
      !menuItem.notVisibleKioskSelfCheckout,
    );
    this.items = this.items.map(item => {
      if (item.id === menuItem.id) {
        item.notVisibleKioskSelfCheckout = !menuItem.notVisibleKioskSelfCheckout;
      }
      return item;
    });
  }

  @action.bound
  async changeCategoryKioskSelfCheckoutVisibility(
    category: DishGroupManagement,
  ) {
    await httpFacade.menu.changeNotVisibleKioskSelfCheckout(
      category.id,
      !category.notVisibleKioskSelfCheckout,
    );
    this._categories = this._categories.map(item => {
      if (item.id === category.id) {
        item.notVisibleKioskSelfCheckout = !category.notVisibleKioskSelfCheckout;
      }
      return item;
    });
  }
}

export default CategoriesStore;
