import { TaskState, TaskAction } from 'stores/Recognition/types';

export enum SocketDataType {
  NEW_TRANSACTION = 'NEW_TRANSACTION',
  taskUpdate = 'taskUpdate',
  NEW_ONLINE_ORDERS = 'NEW_ONLINE_ORDERS',
  DEPOSIT_ITEMS_REQUEST_CONFIRM = 'DEPOSIT_ITEMS_REQUEST_CONFIRM',
  DEPOSIT_ITEMS_REQUEST_COMPLETED = 'DEPOSIT_ITEMS_REQUEST_COMPLETED',
}

export interface TransactionData {
  type: SocketDataType.NEW_TRANSACTION;
  payload: {
    source: 'EXTERNAL' | 'INTERNAL';
  };
}

export interface TaskUpdateData {
  type: SocketDataType.taskUpdate;
  payload: {
    id: string;
    type: string;
    state: TaskState;
    metrics: Record<string, string> & {
      progress?: string;
    };
    wfAllowedActions: TaskAction[];
    finishedAt: string;
  };
}

export interface NewOnlineOrdersData {
  type: SocketDataType.NEW_ONLINE_ORDERS;
  payload: {
    lastCreatedDate: string;
  };
}

export interface DepositItemsRequestConfirmData {
  type: SocketDataType.DEPOSIT_ITEMS_REQUEST_CONFIRM;
  payload: {
    deviceName: string;
    pin: string;
  };
}

export interface DepositItemsRequestCompleteData {
  type: SocketDataType.DEPOSIT_ITEMS_REQUEST_COMPLETED;
  payload: {
    deviceName: string;
  };
}
