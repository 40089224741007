import React from 'react';
import classNames from 'classnames';
import styles from './Row.module.scss';

interface RowProps {
  className?: string;
  children?: React.ReactNode;
}

const Row: React.FC<RowProps> = ({ children, className }: RowProps) => (
  <div className={classNames(styles.row, className)}>{children}</div>
);

export default Row;
