import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { sortByAccessor } from 'helpers/accessors';
import { SortConfig } from 'helpers/types';
import {
  DishGroupManagement,
  MenuItem,
  UpsellingList,
} from 'stores/Menu/types';

import { IGroupTag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import RootStore from 'stores/RootStore';

interface UpsellingListOverview extends UpsellingList {
  items: IGroupTag[];
}

const mapMenuItemToTag = (item: MenuItem): IGroupTag => {
  return {
    id: item.id,
    title: item.title,
    groupId: item.dishGroup.id,
  };
};

export class UpsellingItemsStore {
  @observable loading: boolean;
  @observable
  lists: Record<number, UpsellingListOverview> = {
    0: {
      order: 0,
      title: RootStore.localization.formatMessage('upselling.list.name1'),
      enabled: false,
      menuItemsIds: [],
      dishGroupsIds: [],
      items: [],
    },
    1: {
      order: 1,
      title: RootStore.localization.formatMessage('upselling.list.name2'),
      enabled: false,
      menuItemsIds: [],
      dishGroupsIds: [],
      items: [],
    },
  };

  private defaultSortByTitle: SortConfig = {
    accessor: 'title',
    desc: false,
  };

  @observable private _menuItems: MenuItem[] = [];
  @observable private _dishGroups: DishGroupManagement[] = [];
  @observable private _menuItemsMap: Map<string, MenuItem[]> = new Map();
  @observable private readonly _menuItem: MenuItem | null;

  constructor(
    item: MenuItem | null = null,
    dishGroups: DishGroupManagement[] = [],
  ) {
    this._menuItem = item;
    this._dishGroups = dishGroups;
    this.init();
  }

  @computed
  get upsellingItemsDto(): UpsellingList[] {
    return Object.values(this.lists).map(list => {
      const dishGroupIds = list.items.filter(it => it.isGroup).map(it => it.id);
      const menuItemsIds = list.items
        .filter(it => {
          return (
            !it.isGroup && !(it.groupId && dishGroupIds.includes(it.groupId))
          );
        })
        .map(it => it.id);

      return {
        menuItemsIds,
        dishGroupsIds: dishGroupIds,
        order: list.order,
        title: list.title,
        enabled: list.enabled,
      };
    });
  }

  @computed
  get menuItems(): IGroupTag[] {
    const menuTags: IGroupTag[] = [];
    this._dishGroups.forEach(dishGroup => {
      const menuItems =
        this._menuItemsMap
          .get(dishGroup.id)
          ?.slice()
          .sort(sortByAccessor(this.defaultSortByTitle))
          .map(mapMenuItemToTag) || [];

      if (menuItems.length) {
        menuTags.push(
          { id: dishGroup.id, title: dishGroup.title, isGroup: true },
          ...menuItems,
        );
      }
    });

    return menuTags;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const menuItems = await httpFacade.menuItems.fetchMenuItems();

      this._menuItems = menuItems.data;

      this._menuItems.forEach(item => {
        const items = this._menuItemsMap.get(item.dishGroup.id) || [];
        this._menuItemsMap.set(item.dishGroup.id, [...items, item]);
      });

      if (this._menuItem?.upsellingLists?.length) {
        this.lists = this._menuItem.upsellingLists.map(list => {
          const items: IGroupTag[] = [];

          if (list.dishGroupsIds?.length) {
            list.dishGroupsIds.forEach(dishGroupId => {
              const dishGroupItems = this._menuItemsMap.get(dishGroupId);

              if (dishGroupItems?.length) {
                items.push(...dishGroupItems.map(mapMenuItemToTag));

                items.push({
                  isGroup: true,
                  id: dishGroupId,
                  title: dishGroupItems[0].dishGroup.title,
                });
              }
            });
          }

          list.menuItemsIds?.forEach(itemId => {
            const item = this._menuItems.find(it => it.id === itemId);

            if (item) {
              items.push(mapMenuItemToTag(item));
            }
          });

          return {
            ...list,
            items,
          };
        });
      }
    } catch (e) {
      Log.error(e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  selectItems(listId: string, items: IGroupTag[]) {
    this.lists[listId].items = items;
  }

  @action.bound
  removeItems(listId: string, id?: string) {
    const isGroup = this.lists[listId].items.find(
      (item: IGroupTag) => item.id === id,
    )?.isGroup;

    this.lists[listId].items = id
      ? this.lists[listId].items.filter(
          (item: IGroupTag) =>
            item.id !== id && (isGroup ? item.groupId !== id : true),
        )
      : [];
  }

  @action.bound
  onToggleList(listId: string, isEnabled: boolean) {
    this.lists[listId].enabled = isEnabled;
  }
}
