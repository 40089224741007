import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './AdditionalSpecifications.module.scss';

import DishForm from 'stores/Forms/DishForm/DishForm';
import Specification from './Specification';

@inject('form')
@observer
class AdditionalSpecifications extends Component<{ form?: DishForm }> {
  render() {
    const { form } = this.props;
    const { co2, ingredients } = form!;
    const ingredientsTitles = Object.keys(ingredients);

    return (
      <>
        <div>
          <h3 className={style.spec__title__group}>
            <FormattedMessage id="title.ingredients" />
          </h3>

          <ul className={style.spec__values}>
            {!ingredientsTitles?.length && (
              <span className={style.spec__value}>-</span>
            )}
            {ingredientsTitles?.map((title, index) => {
              return (
                <li key={index}>
                  {title && (
                    <span className={style.spec__title}>
                      <FormattedMessage id={title.toUpperCase()} />
                    </span>
                  )}
                  <span className={style.spec__value}>
                    {ingredients[title] || '-'}
                  </span>
                  {ingredientsTitles.length > 1 &&
                    index < ingredientsTitles.length - 1 && (
                      <span className={style.devider}>|</span>
                    )}
                </li>
              );
            })}
          </ul>

          <h3 className={style.spec__title__group}>
            <FormattedMessage id="title.CO2" />
          </h3>

          <span className={style.spec__title}>{co2 || '-'}</span>
        </div>
        <Specification />
      </>
    );
  }
}

export default AdditionalSpecifications;
