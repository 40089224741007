import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { IOrderTipsReport } from 'stores/Report/types';

import style from './OrderTipsReport.module.scss';
import { FormattedMessage } from 'react-intl';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Table from 'components/Table/Table';
import classNames from 'classnames';
import config from './columnsConfig';
import { SortingRule } from 'react-table';
import IReportStore from 'stores/Report/IReportStore';

const OFFSET_TITLE = 80;
const OFFSET_AMOUNT = 100;

interface Props {
  report: IOrderTipsReport[];
  tableStyle?: string;
  store?: IReportStore;
}

@observer
class ReportTable extends Component<Props> {
  @observable styles = new Map();

  onResize = newResized => {
    newResized.forEach(it => {
      this.styles.set(it.id, it.value);
    });
  };

  onSort = (newSorted: SortingRule[], column: any, additive: boolean): void => {
    if (newSorted.length) {
      this.props.store?.changeSortProperty({
        id: newSorted[0].id,
        desc: newSorted[0].desc,
      });
    } else {
      this.props.store?.changeSortProperty(null);
    }
  };

  render() {
    const { report, tableStyle } = this.props;

    return (
      <>
        <div className={style.table__wrapper}>
          <div className={style.total__row}>
            <div
              className={style.total__value}
              style={
                this.styles.get('title') && {
                  width: this.styles.get('title') + OFFSET_TITLE,
                }
              }
            >
              <FormattedMessage id="title.date" />
            </div>

            <div
              className={style.total__value}
              style={
                this.styles.get('allAmount') && {
                  width: this.styles.get('allAmount') + OFFSET_AMOUNT,
                }
              }
            >
              {report.length &&
                report.map(it => it.allAmount).reduce((acc, it) => acc + it)}
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={
                  report.length &&
                  report.map(it => it.cashAmount).reduce((acc, it) => acc + it)
                }
                withSymbol={false}
              />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={
                  report.length &&
                  report.map(it => it.cashBaseVat).reduce((acc, it) => acc + it)
                }
                withSymbol={false}
              />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={
                  report.length &&
                  report
                    .map(it => it.cashReducedVat)
                    .reduce((acc, it) => acc + it)
                }
                withSymbol={false}
              />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={
                  report.length &&
                  report
                    .map(it => it.nonCashAmount)
                    .reduce((acc, it) => acc + it)
                }
                withSymbol={false}
              />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={
                  report.length &&
                  report
                    .map(it => it.nonCashBaseVat)
                    .reduce((acc, it) => acc + it)
                }
                withSymbol={false}
              />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={
                  report.length &&
                  report
                    .map(it => it.nonCashReducedVat)
                    .reduce((acc, it) => acc + it)
                }
                withSymbol={false}
              />
            </div>
          </div>

          <Table
            data={report}
            columns={config()}
            className={classNames(style.table, tableStyle)}
            onResizedChange={this.onResize}
            tableClassName={style.reportTable}
            defaultSorted={[{ id: 'date', desc: true }]}
            onSortedChange={this.onSort}
          />
        </div>
      </>
    );
  }
}

export default ReportTable;
